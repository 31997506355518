import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card,Alert, Nav } from 'react-bootstrap';
import { useFirebase } from "../context/Firebase";
import Logo from '../assets/images/logo.png'; // Importing the image file


const ForgotPassword = () => {
  const firebase = useFirebase();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
       const response = await firebase.sendPasswordResetEmail(email);
       console.log(response);
      setMessage('Password reset email sent. Check your inbox.');
      setError('');
    } catch (err) {
        console.log(err);
      setError('Failed to send password reset email.');
      setMessage('');
    }

    setLoading(false);
  };

  return (
    <Container fluid className="vh-100">
      <Row className="h-100">
        {/* Left Panel */}
        <Col md={7} className="d-none d-md-flex flex-column justify-content-center align-items-center bg-dark text-white p-4">
          <Card className="bg-dark text-white border-0">
            <Card.Body className="text-center">
              <Card.Img variant="top" src={Logo} alt="Company Logo" className="mb-4" />
              <Card.Title>SAVE INCOME TAX ! TAKE SUPPORT OF PROFESSIONALS</Card.Title>
              <Card.Text hidden>
                <ul className="list-unstyled">
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lectus quis
          sapien congue aliquam. Nulla facilisi.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lectus quis
          sapien congue aliquam. Nulla facilisi.</li>
                  <li>Feature 3: Description</li>
                  <li>Feature 4: Description</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Right Panel */}
        <Col md={5} className="d-flex flex-column justify-content-center align-items-center p-4">
          <div className="w-100" style={{ maxWidth: '600px' }}>
            <Card.Body>
             <center> <h2><Nav.Link href="/"> My Tax Doctor</Nav.Link></h2></center>
             <br>
             </br>
             <br></br>
              <Card.Title className="text-center mb-4">Forgot Password</Card.Title>
              <Form onSubmit={handleSubmit}>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group controlId="formBasicEmail" className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? 'Sending...' : 'Send Password Reset Email'}
      </Button>
    </Form>
              
              </Card.Body>
          </div>
        </Col>
      </Row>
    </Container>






    
  );
};

export default ForgotPassword;
