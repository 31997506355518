import React, { useEffect, useState } from "react";
import { useFirebase } from "../context/Firebase";
import BookCard from "../components/Card";
import { Container ,Card, Row, Col, Alert} from 'react-bootstrap'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { CheckoutInitiate } from "../components/Checkout";
import axios from "axios";
import { initializeCashfree } from "../components/CashreePay"; // Adjust the path as needed
import PayCard from "../components/PaymentCard";

const OrdersPage = () => {
  const firebase = useFirebase();
  const [form16, setForm16] = useState([]);
  const [checkedout, setcheckedout] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [orderAmount, setOrderAmount] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [paymentSessionId, setpaymentSessionId] = useState('');
  const [customerid, setCustomerid] = useState('');
  const [docSubmited, setDocSubmited] = useState(true);
  const [pay_successful, setpay_successful] = useState(false);

  const hooks = {
    enqueueSnackbar: enqueueSnackbar,
    navigate: navigate
};
  useEffect(() => {

    if (firebase.isLoggedIn)
      firebase.fetchMyForm16(firebase.user.uid)?.then((form16) => setForm16(form16.docs));
      firebase.fetchMypayment(firebase.user.uid)?.then((checkedout) => setcheckedout(checkedout.docs));
      console.log(firebase.user);
      console.log('firebase.user');
      setCustomerName(firebase.user.displayName);
      setCustomerEmail(firebase.user.email);
      setCustomerPhone('+91222222222');
      setOrderAmount(200);
      setCustomerid(firebase.user.uid);
      if(form16.length>0)
        {
          debugger
          console.log(form16[0]);
          setCustomerPhone('+91'+ form16[0]._document.data.value.mapValue.fields.mobile_no.stringValue);
          setCustomerName(form16[0]._document.data.value.mapValue.fields.full_name.stringValue);
          setDocSubmited(true);

        }
        else
          {
            setDocSubmited(false);
  
          }
      

  }, [firebase,form16.length,checkedout.length]);
  const [selectedPlan, setSelectedPlan] = useState('Basic Plan');

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };
  const handleSubmit = async()=>{
    navigate('/upload');
  }
  

  const handlePayment = async () => {
    //alert(`You have selected ${selectedPlan}. Proceeding to payment...`);
    debugger

    const plan = selectedPlan;
    var cost = 499.00;
    if(plan==='Basic Plan')
      {
        setOrderAmount(499.00);
        cost= 499.00;

      }
      if(plan==='Standard Plan')
        {
          setOrderAmount(1499.00)
          cost= 1499.00;
        }
        if(plan==='Premium Plan')
          {
            setOrderAmount(2499.00)
            cost= 2499.00;
          }
    const data={
      "orderAmount": cost,
      "customerId": customerid,
      "customerName": customerName,
      "customerEmail": customerEmail,
      "customerPhone": customerPhone
    }
    console.log(data);

    const cashfree = await initializeCashfree();
    
    try {
      const response = await axios.post('https://us-central1-taxdoc-97856.cloudfunctions.net/createOrder', data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      console.log('Order created:', response.data);
      console.log(response.data["payment_session_id"]);
      // Enter data into Database///////////////////////////////////
      const pay_response= await firebase.handleCreateCheckout(customerEmail, customerPhone,response.data["cf_order_id"],response.data["payment_session_id"], 'Initiated',customerid,cost);
      console.log(pay_response);
      console.log('pay_response');

      const pay_id_db = pay_response._key.path.segments[1];


      let checkoutOptions = {
        paymentSessionId: response.data["payment_session_id"],
        redirectTarget: "_modal"
    };

  cashfree.checkout(checkoutOptions).then( async ( result) =>  {
        if(result.error){
            // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
            console.log("User has closed the popup or there is some payment error, Check for Payment Status");
            console.log(result.error);
        }
        if(result.redirect){
            // This will be true when the payment redirection page couldnt be opened in the same window
            // This is an exceptional case only when the page is opened inside an inAppBrowser
            // In this case the customer will be redirected to return url once payment is completed
            console.log("Payment will be redirected");
        }
        if(result.paymentDetails){
            // This will be called whenever the payment is completed irrespective of transaction status
            console.log("Payment has been completed, Check for Payment Status");
            console.log(result.paymentDetails.paymentMessage);
            console.log(result);
            try {
              const pay_response2 = await firebase.handleUpdateCheckout(pay_id_db, 'success');
              console.log(pay_response2);
              setpay_successful(true);
              // update payment details//////////////////
          } catch (error) {
              console.error('Error updating payment details:', error);
          }
            // update payment details//////////////////
            
        }
    });




      // Handle the response, e.g., redirect to payment page or display order details
    } catch (error) {
      console.error('Error creating order:', error);
      // Handle error, e.g., display an error message
    }
  };



  
  if (!firebase.isLoggedIn) return <div className="container my-4 text-center"> <h1>Please log in to proceed further</h1></div>;

  return (
    <div>
      <div>
    <div style={{ backgroundColor: '#2a4d99' }}>
      
    
    <Container className="mx-4">
      <Card className="text-white" style={{ backgroundColor: '#2a4d99', padding: '20px' }}>
        <Card.Body>
          <Card.Title as="h1"><center>Orders</center></Card.Title>
          <Card.Text>
       
          </Card.Text>
        </Card.Body>
      </Card>

</Container>

</div>
</div>
<Container className="mt-5">
{pay_successful &&
  <Alert variant="success">Paymaent has been completed successfully!</Alert>
}
{ form16.length>0 &&
  <Row>
  <h3 className="py-2"> Your Submitted Data</h3>
  {form16.map((form16) => (
    <Col md={4} key={form16.id} className="d-flex align-items-stretch">
      <BookCard
        link={`/form16/${form16.id}`}
        id={form16.id}
        {...form16.data()}
      />
    </Col>
  ))}
</Row>
  }
{ checkedout.length>0 &&
  <Row>
  <h3 className="py-2"> Payments</h3>
  <h6 style={{"color":"#ccc"}}>(You have successfully made payment. You do not need to pay again.)</h6>
  {checkedout.map((checkedout) => (
    <Col md={4} key={checkedout.id} className="d-flex align-items-stretch">
      
      <PayCard
        id={checkedout.id}
        {...checkedout.data()}
      />
      
    </Col>
  ))}
</Row>
}


{(form16.length===0) &&
    <Row>
    <h4 className="py-2" style={{border:"1px solid #ccc", borderBottomWidth: "4px"} }> You have not submiited your data yet.</h4>
    <div className="text-center">
        {!docSubmited &&<button className="btn btn-default" onClick={handleSubmit}>Please Submit your Data First</button>}
        
      </div>
</Row>
}
    </Container>



      <div className="container mt-5">
      <h3 className="py-2"> Choose your plan</h3>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card mb-4" style={{ backgroundColor:"#f1f1f1" }}>
            <div className="card-body">
              <h5 className="card-title">Basic Plan</h5>
              <p className="card-text">Salary + Interest Income(salary Upto 5L/A from one employer)</p>
              <h3 className="card-text">₹499/month</h3>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="plan" 
                  id="plan1" 
                  value="Basic Plan" 
                  checked={selectedPlan === 'Basic Plan'} 
                  onChange={handlePlanChange} 
                />
                <label className="form-check-label" htmlFor="plan1">
                  Select Basic Plan
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4" style={{ backgroundColor:"#ccc" }}>
            <div className="card-body">
              <h5 className="card-title">Standard Plan</h5>
              <p className="card-text">Salary + Interest Income(salary Upto 5-10 L/A from one employer)</p>
              <h3 className="card-text">₹1499/month</h3>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="plan" 
                  id="plan2" 
                  value="Standard Plan" 
                  checked={selectedPlan === 'Standard Plan'} 
                  onChange={handlePlanChange} 
                />
                <label className="form-check-label" htmlFor="plan2">
                  Select Standard Plan
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4" style={{ backgroundColor:"#f1f1f1" }}>
            <div className="card-body">
              <h5 className="card-title">Premium Plan</h5>
              <p className="card-text">Salary + Interest Income(salary More than 10L/A from one or more employer)</p>
              <h3 className="card-text">₹2499/month</h3>
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="plan" 
                  id="plan3" 
                  value="Premium Plan" 
                  checked={selectedPlan === 'Premium Plan'} 
                  onChange={handlePlanChange} 
                />
                <label className="form-check-label" htmlFor="plan3">
                  Select Premium Plan
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        {docSubmited &&<button className="btn btn-primary" onClick={handlePayment}>Make Payment</button>}
        
      </div>
      <div className="text-center">
        {!docSubmited &&<button className="btn btn-default" onClick={handleSubmit}>Please Submit your Data First</button>}
        
      </div>
    </div>
    </div>
  );
};

export default OrdersPage;
