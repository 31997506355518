import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card,Alert, Nav } from 'react-bootstrap';
import { useFirebase } from "../context/Firebase";
import Logo from '../assets/images/logo.png'; // Importing the image file

const LoginPage = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (firebase.isLoggedIn) {
      // navigate to home
      navigate("/");
    }
  }, [firebase, navigate]);


  const handleCreateUser = () => {
    navigate('/register');
  };

  const handleForgotPassword = () => {
    navigate('/forgotpassword');
  };


  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } 
    else 
    {
      debugger
      console.log("login in a user...");
      try {
        const result = await firebase.singinUserWithEmailAndPass(email, password);
        // Handle successful sign-in (e.g., redirect to dashboard)
        console.log(result);
      } catch (error) {
        handleFirebaseError(error);
      }
    }
    setValidated(true);
  };
  const handleFirebaseError = (error) => {
    console.log(error.code);
    switch (error.code) {
      case 'auth/invalid-login-credentials':
        setError('invalid-login-credentials.');
        break;
      case 'auth/user-disabled':
        setError('The user corresponding to the given email has been disabled.');
        break;
      case 'auth/user-not-found':
        setError('There is no user corresponding to the given email.');
        break;
      case 'auth/wrong-password':
        setError('The password is invalid or the user does not have a password.');
        break;
      default:
        setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Container fluid className="vh-100">
      <Row className="h-100">
        {/* Left Panel */}
        <Col md={7} className="d-none d-md-flex flex-column justify-content-center align-items-center bg-dark text-white p-4">
          <Card className="bg-dark text-white border-0">
            <Card.Body className="text-center">
              <Card.Img variant="top" src={Logo} alt="Company Logo" className="mb-4" />
              <Card.Title>SAVE INCOME TAX ! TAKE SUPPORT OF PROFESSIONALS</Card.Title>
              <Card.Text hidden>
                <ul className="list-unstyled">
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lectus quis
          sapien congue aliquam. Nulla facilisi.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lectus quis
          sapien congue aliquam. Nulla facilisi.</li>
                  <li>Feature 3: Description</li>
                  <li>Feature 4: Description</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Right Panel */}
        <Col md={5} className="d-flex flex-column justify-content-center align-items-center p-4">
          <div className="w-100" style={{ maxWidth: '600px' }}>
            <Card.Body>
            <center> <h2><Nav.Link href="/"> My Tax Doctor</Nav.Link></h2></center>
             <br>
             </br>
              <Card.Title className="text-center mb-4">Login</Card.Title>
              <Button onClick={firebase.signinWithGoogle} variant="danger">
              Signin with Google
            </Button>
            <p className="mt-5 mb-5">OR</p>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {error && <Alert variant="danger">{error}</Alert>}
                  <Form.Group controlId="formBasicEmail" className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password.
                  </Form.Control.Feedback>
                </Form.Group>
      <Button variant="link" className="p-0" onClick={handleForgotPassword}>Forgot Password?</Button>
      <Button variant="primary" type="submit" className="w-100 mb-3">
        Login
      </Button>
      <div className="d-flex justify-content-between">
        <p>Don't have an account?</p>
        <Button variant="link" onClick={handleCreateUser} className="p-0">Create User</Button>
      </div>
    </Form>
              
              
              </Card.Body>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
