import React from 'react'
import { Container, Card } from 'react-bootstrap'

const AboutUs = () => {
  return (
    <div>


<div>
    <div style={{ backgroundColor: '#2a4d99' }}>
      
    
    <Container className="mx-4">
      <Card className="text-white" style={{ backgroundColor: '#2a4d99', padding: '20px' }}>
        <Card.Body>
          <Card.Title as="h1"><center>About Us</center></Card.Title>
          <Card.Text>
            
          </Card.Text>
        </Card.Body>
      </Card>





</Container>

</div>
</div>
        <Container>
        <h1 className="my-4">
        Welcome to MyTaxDoctor!</h1>

<p>At MyTaxDoctor, we're dedicated to providing comprehensive financial services to individuals, businesses, and non-profit organizations. With a team of experienced professionals, we specialize in income tax management, company registration, NGO registration, as well as a wide array of loan and financial services.
</p>

<h2>Our Mission:</h2>

<p>At the core of our mission is a commitment to simplifying complex financial processes and empowering our clients to achieve their financial goals. Whether you're a small business owner navigating tax regulations, an entrepreneur establishing a new company, or a non-profit organization seeking registration, we're here to guide you through every step of the journey.
</p>

<h2>Expertise and Experience:</h2>

<p>With years of experience in the financial industry, our team brings a wealth of knowledge and expertise to every client engagement. Our tax specialists stay abreast of the latest tax laws and regulations to ensure that our clients receive accurate and up-to-date advice tailored to their specific needs. Likewise, our company registration experts possess in-depth knowledge of regulatory requirements, making the process of establishing a business smooth and hassle-free.
</p>
<h2>Services We Offer:</h2>

<ul><li>
    Income Tax Services: From individual tax returns to corporate tax planning, we offer a full range of income tax services designed to minimize your tax liability and maximize your financial well-being.
    </li>
<li>Company Registration: Whether you're starting a new venture or expanding your existing business, our company registration services are designed to streamline the process and ensure compliance with all legal requirements.
</li>
<li>
NGO Registration: As advocates for positive social change, we understand the importance of proper NGO registration. Our experts will guide you through the registration process, enabling you to focus on your mission and make a difference in the community.
</li>
<li>
Loan and Financial Services: Need funding to fuel your growth? Our loan and financial services are designed to provide you with access to capital when you need it most. From business loans to personal finance solutions, we're here to help you achieve your financial goals.
</li></ul>

</Container>
    </div>
  )
}

export default AboutUs