import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";


const BookCard = (props) => {
  const date = new Date(props.created_on.seconds * 1000 + props.created_on.nanoseconds / 1000000);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <Card style={{ width: "32rem", margin: "0px"}}>
      
      <Card.Body>
        <Card.Title>{props.pan_card}</Card.Title>
        <Card.Text>
        </Card.Text>
        Name- {props.full_name}
        <br></br>
        Contact No.- {props.mobile_no} <br></br>
        Submitted On - {formattedDate}
       
      </Card.Body>
    </Card>
  );
};

export default BookCard;
