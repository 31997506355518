import React, { useState, useRef,useEffect } from 'react';
import { Form, Button, Row, Col, Alert, Container, Card } from 'react-bootstrap';
import { useFirebase } from "../context/Firebase";
import { useNavigate } from "react-router-dom";



const TaxFilingPage = () => {
  const navigate = useNavigate();
  const firebase = useFirebase();

  const handleCAClick=()=>{
    if(firebase.isLoggedIn)
      {
        navigate("/upload");
      }else
      {
        navigate("/login");
      }
    }
    const [pancard, setPancard] = useState('');
    const [mobileNo, setmobileNo] = useState('');
    const [name, setName] = useState('');
    const [coverPic, setCoverPic] = useState(null);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const fileInputRef = useRef(null);
  
    useEffect(() => {
      if (!firebase.isLoggedIn) {
        // navigate to home
        //navigate("/");
      }
    }, [firebase, navigate]);
  
  
    const validate = () => {
      let newErrors = {};
      if (!pancard) newErrors.pancard = 'PAN Card is required';
      if (!mobileNo) newErrors.mobileNo = 'Mobile Number is required';
      if (!name) newErrors.name = 'Name is required';
      //if (!coverPic) newErrors.coverPic = 'Form 16 is required';
      return newErrors;
    };
  
    const handleSubmit = async (e) =>  {
      e.preventDefault();
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setSubmitted(false);
      } else {
        debugger
        // handle form submission
        console.log({ pancard, mobileNo, name, coverPic });
        setErrors({});
        //setSubmitted(true);
        const result= await firebase.handleCreateNewListing(pancard, mobileNo, name, coverPic );
        console.log(result);
        window.open('https://eportal.incometax.gov.in/iec/foservices/#/login', "_blank");
        handleReset();
      }
    };
    const handleReset = () => {
      setName('');
      setPancard('');
      setCoverPic('');
      setName('');
      setmobileNo('');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      //setErrors({});
      //setSubmitted(false);
    };
  

  return (
    <div>
    <div style={{ backgroundColor: '#2a4d99' }}>
      
    
    <Container className="mx-4">
      <Card className="text-white" style={{ backgroundColor: '#2a4d99', padding: '20px' }}>
        <Card.Body>
          <Card.Title as="h1">File Your Income Tax Return For FREE*</Card.Title>
          <Card.Text>
            My Tax Doctor has made Online Income Tax Return Filing super easy! Take the first step towards ITR filing. 
            Select all the sources of your income (to accurately file) and click on “Start Your ITR Filing” 
            to experience My Tax Doctor's customized & easiest income tax filing process.
          </Card.Text>
        </Card.Body>
      </Card>
</Container>
</div>
    <div style={{ backgroundColor: '#f1f1f1' }}>
      
    
    <Container className="mx-4 my-4">
      <Row className="mx-4">
        <Col md={6} className="d-flex justify-content-center align-items-center">

          {submitted && <Alert variant="success">Your Data created successfully. We will connect you soon!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>PAN Card</Form.Label>
              <Form.Control
                onChange={(e) => setPancard(e.target.value)}
                value={pancard}
                type="text"
                placeholder="Enter Pan Card"
                isInvalid={!!errors.pancard}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pancard}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                onChange={(e) => setmobileNo(e.target.value)}
                value={mobileNo}
                type="text"
                placeholder="10-digit Mobile No."
                isInvalid={!!errors.mobileNo}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobileNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Enter Name"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Form 16(Optional)</Form.Label>
              <Form.Control
                onChange={(e) => setCoverPic(e.target.files[0])}
                type="file"
                isInvalid={!!errors.coverPic}
                ref={fileInputRef}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coverPic}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Start Your ITR Filling
            </Button>
          </Form>
        </Col>


        <Col md={6}>
          <Card className="p-3" style={{ backgroundColor: '#f1f1f1' }}>
            <Card.Body>
              <Card.Title as="h5">Need an expert CA For Your Income Tax Return Filing?</Card.Title>
              <Card.Text>
                Get your personal CA to do tax filing for you. It is personal, convenient & fast with maximum refund calculated for you.
                <ul>
                  <li>Save time and money</li>
                  <li>Trusted by 1M+ Customers</li>
                  <li>4.8 Service Rating</li>
                  <li>Reliable & Secure ITR Filing Platform</li>
                </ul>
              </Card.Text>
              <Button variant="primary" size="lg" onClick={handleCAClick}>Start your IT Return</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
    </div>
  );
};

export default TaxFilingPage;
