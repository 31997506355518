import React, { useEffect, useState } from "react";
import CardGroup from "react-bootstrap/CardGroup";

import BookCard from "../components/Card";
import { useFirebase } from "../context/Firebase";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import ITRleft from '../assets/images/tax-final.png'; // Importing the image file
import itryourself from '../assets/images/itr-yourself.jpg'; // Importing the image file
import BenefitsCards from "../components/BenefitsCard";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();

  const handleECA= ()=>{
    if(firebase.isLoggedIn)
      {
        navigate('/orders');
      }
      else
      navigate('/login');
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
    <div className="container mt-5">
      <Row className="my-4">
        <Col md={4} className="d-flex flex-column justify-content-center">
          <h1 style={{color:'#000080'}}><b>ITR Filling</b> was never so easy in<b> India</b></h1>
          <br></br>
          <img variant="bottom"
            src={ITRleft} 
            alt="Cartoon of person doing ITR" 
            className="img-fluid"
          />
        </Col>
        <Col md={4}>
          <Card className="h-100" style={{ backgroundColor: 'green', border: '4px solid green', color: 'white' }}>
            <Card.Img variant="top" src={itryourself} height={200} alt="ITR Filling Yourself" />
            <Card.Body>
              <Card.Title>ITR Filling Yourself</Card.Title>
              <Card.Text>
                You can file your Income tax return on your own. It’s fast, easy and safe when you file ITR with Tax2win on your own.
              </Card.Text>
              <Button variant="primary" href="/taxfilling">File ITR Now</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100" style={{ backgroundColor: 'blue', border: '4px solid blue',color: 'white' }}>
            <Card.Img variant="top" src={itryourself} height={200} alt="ITR Filling by CA" />
            <Card.Body>
              <Card.Title>ITR Filling by CA</Card.Title>
              <Card.Text>
                Hire personal CA to do Income Tax Filing for you. It is convenient & fast with maximum refund calculated for you.
              </Card.Text>
              <Button variant="primary" onClick={handleECA} >Book CA</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
    <div className="contaner mt-5">
      
      <BenefitsCards></BenefitsCards>
    </div>
    
    </div>
  );
};

export default HomePage;
