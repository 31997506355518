import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useFirebase } from "../context/Firebase";
import Logo from '../assets/images/logo.png'; // Importing the image file
import { useNavigate } from "react-router-dom";

const Navbar1 = () => {
    const firebase = useFirebase();
  const navigate = useNavigate();
  const [email, setEmail]=useState('');

  useEffect(() => {
    debugger
    if(firebase.isLoggedIn)
      {
       if(firebase.user!=null)
        {
          setEmail(firebase.user.email);

        }
        else
        {
          setEmail('');
        }
      }
  }, [firebase]);

  const handleOrders= async()=>
    {
      navigate('/orders');
    }
  const handleClick= async()=>{
    navigate('/');
  }

  const handleSignout= async ()=>{
    try {
      console.log('200');
      await firebase.signUutUser();
      setEmail('');
      navigate('/login'); // Redirect to sign-in page after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
}

  return (
    <div>


            <Navbar bg="light" expand="lg">
                <Container>
                <Navbar.Brand href="#home">
          <img src={Logo} alt="my tax doctor" onClick={handleClick}></img>
        </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href="/taxfilling">File ITR</Nav.Link>
                        <Nav.Link href="/aboutus">About Us</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                        <Nav.Link href="/termsandconditions">Terms and Conditions</Nav.Link>
                        <Nav.Link href="/refundcancellationpolicy">Refund Policy</Nav.Link>
                        </Nav>
                        
                        <Nav>
                        <p className="pt-2 px-2">{email}</p>
        
        {firebase.isLoggedIn ? (
          <div>
            <Button className="mx-2" onClick={handleOrders}>My Orders</Button>
            <Button onClick={handleSignout}>Sign Out</Button>
            
          </div>
        ) : (
          <Button href="/login">Sign In</Button>
        )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


    </div>
  )
}

export default Navbar1