import { Routes, Route, useLocation } from "react-router-dom";

// Components
import MyNavbar from "./components/Navbar1";
import Footer from "./components/Footer";

// Pages
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";

import HomePage from "./pages/Home";
import TaxFilingPage from "./pages/TaxFiling";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ForgotPassword from "./pages/ForgotPassword";
import UploadForm from "./pages/Upload";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import TermsandConditions from "./pages/TermsandConditions";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";
import OrdersPage from "./pages/ViewOrder";

function App() {
  const location = useLocation();

  // Define an array of routes where the footer and navbar should be hidden
  const routesWithoutFooterAndNavbar = ["/login","/register","/forgotpassword"];

  // Check if the current route is in the array of routes where footer and navbar should be hidden
  const hideFooterAndNavbar = routesWithoutFooterAndNavbar.includes(location.pathname);

  return (
    <div>
      {!hideFooterAndNavbar && <MyNavbar />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
        <Route path="/refundcancellationpolicy" element={<CancellationRefundPolicy />} />

        <Route path="/taxfilling" element={<TaxFilingPage />} />
        <Route path="/upload" element={<UploadForm />} />
        <Route path="/orders" element={<OrdersPage />} />
        
      </Routes>
      {!hideFooterAndNavbar && <Footer />}
    </div>
  );
}

export default App;
