import React from 'react'
import { Container ,Card} from 'react-bootstrap'

const Contact = () => {
  return (
    <div>
      <div>
    <div style={{ backgroundColor: '#2a4d99' }}>
      
    
    <Container className="mx-4">
      <Card className="text-white" style={{ backgroundColor: '#2a4d99', padding: '20px' }}>
        <Card.Body>
          <Card.Title as="h1"><center>Contact Us</center></Card.Title>
          <Card.Text>
       
          </Card.Text>
        </Card.Body>
      </Card>





</Container>

</div>
</div>
        <Container>
        <h1 className="my-4">
        Welcome to MyTaxDoctor!</h1>

      <p>At MyTaxDoctor, we're dedicated to providing comprehensive financial services to individuals, businesses, and non-profit organizations. With a team of experienced professionals, we specialize in income tax management, company registration, NGO registration, as well as a wide array of loan and financial services.
      </p>

      <h2>Contact Information</h2>
      <b>  Registered Address: </b><br></br>
          4/274/FF-4, VAISHALI, GHAZIABAD, <br></br>
          UTTAR PRADESH <br></br>
          PIN: 201010<br></br><br></br>
        <b>Operational Address:</b><br></br>
          4/274/FF-4, VAISHALI, GHAZIABAD, <br></br>
          UTTAR PRADESH <br></br>
          PIN: 201010<br></br><br></br>
          <b>Telephone No: </b>9717307644<br></br>
          <b>E-Mail ID:</b> loanatsky@gmail.com

</Container>

    </div>
  )
}

export default Contact