import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const BenefitsCards = () => {
  const benefits = [
    {
      title: 'Compliance with Law',
      description: 'Filing income tax returns is mandatory for individuals and entities earning above the taxable threshold set by the government. Compliance ensures adherence to legal requirements and avoids penalties or legal actions.',
    },
    {
      title: 'Documentation of Income',
      description: 'Filing ITR serves as documentation of your income, providing a record of your financial activities. This documentation can be crucial for various purposes such as applying for loans, visa processing, or obtaining financial assistance.',
    },
    {
      title: 'Claiming Tax Refunds',
      description: 'If excess tax has been deducted from your income, filing ITR enables you to claim refunds. This can happen due to various reasons such as investment in tax-saving instruments, deductions, or overpayment of taxes.',
    },
    {
      title: 'Loan Processing',
      description: 'Many financial institutions require income tax returns as proof of income when processing loan applications. It helps lenders assess your creditworthiness and repayment capacity.',
    },
    {
      title: 'Claiming Tax Refunds',
      description: 'If excess tax has been deducted from your income, filing ITR enables you to claim refunds. This can happen due to various reasons such as investment in tax-saving instruments, deductions, or overpayment of taxes.',
    },
    // Add more benefits here...
  ];
  

  return (
    <Container className="my-4">
    <h1 style={{color:'#000080'}}>Benefits of ITR Filing</h1>
    <Row>
      {benefits.map((benefit, index) => (
        <Col md={4} className="mb-4" key={index}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>{benefit.title}</Card.Title>
              <Card.Text>{benefit.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
  );
};

export default BenefitsCards;
