import { createContext, useContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  updateProfile
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const FirebaseContext = createContext(null);


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDrOoFKzuWbKfk5MPdWx4YTrJ-BZZkC8OM",
  authDomain: "taxdoc-97856.firebaseapp.com",
  projectId: "taxdoc-97856",
  storageBucket: "taxdoc-97856.appspot.com",
  messagingSenderId: "637032480766",
  appId: "1:637032480766:web:a8594ef259f4e76438caaf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const useFirebase = () => useContext(FirebaseContext);

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

const googleProvider = new GoogleAuthProvider();

export const FirebaseProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) setUser(user);
      else setUser(null);
    });
  }, []);

  const signupUserWithEmailAndPassword = async (email, password, username) =>{
    const userCredential = createUserWithEmailAndPassword(firebaseAuth, email, password);
    await updateProfile(userCredential.user, { displayName: username });
  };

  const sendPasswordResetEmail= async (email) =>
    await sendPasswordResetEmail(firebaseAuth, email);

  const singinUserWithEmailAndPass = (email, password) =>
    signInWithEmailAndPassword(firebaseAuth, email, password);

  const signinWithGoogle = () => signInWithPopup(firebaseAuth, googleProvider);

  const handleCreateNewListing = async (pan_card, mobile_no, full_name, coverPic) => {
    var path='';
    if(coverPic!=null)
      {
        const imageRef = ref(storage, `uploads/form16/${Date.now()}-${coverPic.name}`);
        const uploadResult = await uploadBytes(imageRef, coverPic);
        path = uploadResult.ref.fullPath;
      }

    return await addDoc(collection(firestore, "form16"), {
      pan_card,
      mobile_no,
      full_name,
      file_path: path,
      user_id: user?.uid==null ? '': user.uid,
      user_email: user?.email==null ? '': user.email,
      display_name: user?.displayName==null ? '': user.displayName,
      photo_url: user?.photoURL==null ? '': user.photoURL,
      created_on: new Date()
    });
  };
  const handleCreateCheckout = async (email, mobile_no, order_id, paymant_session_id, status, user_id, orderAmount) => {
 
    const result=  await addDoc(collection(firestore, "checkout"), {
      amount: orderAmount,
      email,
      order_id,
      session_id: paymant_session_id,
      status: status,
      user_id: user_id,
      mobile_no,
      created_on: new Date()
    });
    return result;
  };

  const handleUpdateCheckout = async (doc_id, status) => {
    try {
        const docRef = doc(firestore, "checkout", doc_id); // Reference to the document in the "checkout" collection
        const result = await updateDoc(docRef, { status: status }); // Update the document's status field
        return result;
    } catch (error) {
        console.error('Error updating checkout status:', error);
        throw error; // Rethrow the error to handle it where the function is called
    }
};

  const listAllBooks = () => {
    return getDocs(collection(firestore, "books"));
  };

  const getBookById = async (id) => {
    const docRef = doc(firestore, "books", id);
    const result = await getDoc(docRef);
    return result;
  };


  const getImageURL = (path) => {
    return getDownloadURL(ref(storage, path));
  };

  const placeOrder = async (bookId, qty) => {
    const collectionRef = collection(firestore, "books", bookId, "orders");
    const result = await addDoc(collectionRef, {
      userID: user.uid,
      userEmail: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      qty: Number(qty),
    });
    return result;
  };

  const fetchMyForm16 = async (userId) => {
    const collectionRef = collection(firestore, "form16");
    const q = query(collectionRef, where("user_id", "==", userId));

    const result = await getDocs(q);
    console.log(result);
    return result;
  };
  const fetchMypayment = async (userId) => {
    const collectionRef = collection(firestore, "checkout");
    const q = query(collectionRef, where("user_id", "==", userId),
    where("status", "==", "success"));

    const result = await getDocs(q);
    console.log(result);
    return result;
  };

  const getOrders = async (bookId) => {
    const collectionRef = collection(firestore, "books", bookId, "orders");
    const result = await getDocs(collectionRef);
    return result;
  };

  const isLoggedIn = user ? true : false;

  const signUutUser = () => signOut(firebaseAuth);

  return (
    <FirebaseContext.Provider
      value={{
        signinWithGoogle,
        signupUserWithEmailAndPassword,
        singinUserWithEmailAndPass,
        handleCreateNewListing,
        listAllBooks,
        getImageURL,
        getBookById,
        placeOrder,
        fetchMyForm16,
        getOrders,
        isLoggedIn,
        user,
        signUutUser,
        sendPasswordResetEmail,
        handleCreateCheckout,
        handleUpdateCheckout,
        fetchMypayment
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
