import React, { useState, useRef,useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useFirebase } from "../context/Firebase";
import { useNavigate } from 'react-router-dom';

const UploadForm = () => {
  const firebase = useFirebase();
  const [pancard, setPancard] = useState('');
  const [mobileNo, setmobileNo] = useState('');
  const [name, setName] = useState('');
  const [coverPic, setCoverPic] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!firebase.isLoggedIn) {
      // navigate to home
      navigate("/");
    }
  }, [firebase, navigate]);


  const validate = () => {
    let newErrors = {};
    if (!pancard) newErrors.pancard = 'PAN Card is required';
    if (!mobileNo) newErrors.mobileNo = 'Mobile Number is required';
    if (!name) newErrors.name = 'Name is required';
    if (!coverPic) newErrors.coverPic = 'Form 16 is required';
    return newErrors;
  };

  const handleSubmit = async (e) =>  {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSubmitted(false);
    } else {
      // handle form submission
      console.log({ pancard, mobileNo, name, coverPic });
      setErrors({});
      setSubmitted(true);
      const result= await firebase.handleCreateNewListing(pancard, mobileNo, name, coverPic );
      console.log(result);
      handleReset();
    }
  };
  const handleReset = () => {
    setName('');
    setPancard('');
    setCoverPic('');
    setName('');
    setmobileNo('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    //setErrors({});
    //setSubmitted(false);
  };

  return (
    <div className="container mt-5">
      <Row>
        <Col md={12}>
        <h2>Form 16A</h2>
        </Col>
        <Col md={6}>
          
          {submitted && <Alert variant="success">Your Data created successfully. We will connect you soon!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>PAN Card</Form.Label>
              <Form.Control
                onChange={(e) => setPancard(e.target.value)}
                value={pancard}
                type="text"
                placeholder="Enter Pan Card"
                isInvalid={!!errors.pancard}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pancard}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                onChange={(e) => setmobileNo(e.target.value)}
                value={mobileNo}
                type="text"
                placeholder="10-digit Mobile No."
                isInvalid={!!errors.mobileNo}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobileNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Enter Name"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Form 16</Form.Label>
              <Form.Control
                onChange={(e) => setCoverPic(e.target.files[0])}
                type="file"
                isInvalid={!!errors.coverPic}
                ref={fileInputRef}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coverPic}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          
          <p><strong>PAN Card:</strong> Enter your PAN (Permanent Account Number) card. </p>
          <p><strong>Mobile No.:</strong>Please Enter your Mobile No. </p>
          <p><strong>Upload Form 16:</strong> Upload form 16. Supported formats are JPEG, PNG, PDF etc.</p>
        </Col>
      </Row>
    </div>
  );
};

export default UploadForm;
