import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="mt-5 bg-light">
      <Container>
        <Row>
          <Col>
            <p className="text-muted small">
              © 2024 My tax doctor. All rights reserved.
            </p>
          </Col>
          <Col className="text-right">
            <p className="text-muted small">
              <a href="/termsandconditions" className="text-muted"> Terms and Conditions</a> | 
              <a href="/contact" className="text-muted"> Contact Us</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
